/* Introduction.css */

.introduction-container {
    text-align: center;
    padding: 2em;
    line-height: 1.6;
    max-width: 800px;
    margin: auto;
  }
  
  .introduction-container h1 {
    color: #ffffff;
    font-size: 2em;
    margin-bottom: 1em;
    margin-top: 60px;
  }
  
  .introduction-container p {
    color: #e0e0e0;
    font-size: 1.2em;
  }
  