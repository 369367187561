.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #000000;
    color: #f7fafc;
    border-bottom: 1px solid #ffffff;
  }
  
  .header-logo {
    font-size: 2em;
    font-weight: bold;
  }
  
  .header-nav {
    display: flex;
    gap: 2em;
  }
  
  .header-nav a {
    cursor: pointer;
    color: #f7fafc;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .header-nav a:hover {
    color: #63b3ed;
  }
  