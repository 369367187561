footer {
    width: 100%;
    background-color: #000000;  /* Modifier cette valeur en fonction de la couleur souhaitée */
    color: #ffffff;  /* Modifier cette valeur en fonction de la couleur souhaitée */
    padding: 20px 0;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.footer-left {
    font-size: 1.2em;
    font-weight: bold;
}

.footer-right {
    width: 60%;
    font-size: 0.8em;
}

/* Ajout de la règle @media pour les écrans de 600px ou moins */
@media (max-width: 600px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-left, .footer-right {
        font-size: 0.8em;
    }

    .footer-right {
        width: 100%;
    }
}
