.accordion-item {
    margin: 10px 0;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
  }
  
  .accordion-title {
    padding: 15px 20px;
    background-color: #f5f5f5;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.1em;
    color: #333;
    border-bottom: 1px solid #ddd;
  }
  
  .accordion-title:hover {
    background-color: #eee;
  }
  
  .accordion-content {
    color: #000000;  /* black text color */
    padding: 15px 20px;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .accordion-container {
    max-width: 800px;  /* Modifier cette valeur en fonction de la largeur souhaitée */
    margin: 0 auto;   /* Centrer le conteneur sur la page */
    padding: 20px;    /* Ajouter un peu d'espace autour du contenu */
  }
  
  