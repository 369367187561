.App {
    background-color: #000000;  /* black background color */
    color: #FFFFFF;             /* white text color */
  }
  
  .header-container {
    margin: 0px 0;
  }
  
  .input-form-container {
    margin: 60px 0;
  }
  
  .output-form-container {
    margin: 40px 0;
  }
  
  .faq-container {
    margin: 100px 0;
  }
  
  .footer-container {
    margin: 0px 0;
  }
  