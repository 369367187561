

@media (min-width: 768px) {
  .fixed-card {
    width: 100%;
    min-width: 200px;
    max-width: 200px; /* Ajustez cette valeur en fonction de vos besoins */
  }
}

